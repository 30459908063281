.a {
  display: flex;
  align-items: center;
}

.a-left {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
}

.a-right {
  flex: 1;
}

.a-card.bg {
  position: absolute;
  top: 50px;
  left: 50px;
  background: #ad94d1;
}

.a-card {
  width: 60%;
  height: 70vh;
  border-radius: 30px;
  position: relative;
  overflow: hidden;
}

.a-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.a-award-img {
  width: 120px;
  height: 120px;
  border-radius: 20px;
}

.a-title {
  font-weight: 800;
  margin-bottom: 30px;
  color: var(--secondary-color);
}

.a-sub {
  margin: 20px 0px;
}

.a-desc{
    padding-top: 1rem;
    line-height: 1.5;
    font-weight: 300;
    font-size: 20px;
    margin-bottom: 30px;
}


.a-award-texts{
    width: 70%;
}

.a-award-title{
    font-weight: bold;
    color: #555;
    margin-bottom: 10px;
}

@media screen and (max-width:480px) {
    .a{
      flex-direction: column;
      text-align: center;
    }

    .a-left {
      width: 100%;
    }

    .a-card {
      height: 30vh;
    }

    .a-card.bg, .a-award {
      display: none;
    }

    .a-right{
      padding: 20px;
    }
}

.pulsingButton {
  width: 220px;
  text-align: center;
  white-space: nowrap;
  display: block;
  margin: 50px auto;
  padding: 10px;
  box-shadow: 0 0 0 0 rgba(61, 166, 232, 0.7);
  border-radius: 10px;
  background-color: rgba(51, 76, 217, 0.915);
  -webkit-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -ms-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  font-size: 22px;
  font-weight: 600;

  font-family: sans-serif;
  text-decoration: none !important;
  color: #ffffff;
  transition: all 300ms ease-in-out;
}

a.pulsingButton:hover {
  -webkit-animation: none;
  -moz-animation: none;
  -ms-animation: none;
  animation: none;
  color: #ffffff;
}


/* Animation */

@-webkit-keyframes pulsing {
  to {
    box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
  }
}

@-moz-keyframes pulsing {
  to {
    box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
  }
}

@-ms-keyframes pulsing {
  to {
    box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
  }
}

@keyframes pulsing {
  to {
    box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
  }
}